/*** General ***/
!function(){"use strict";if("objectFit"in document.documentElement.style!=!1)return void(window.objectFitPolyfill=function(){return!1});var t=function(t){var e=window.getComputedStyle(t,null),i=e.getPropertyValue("position"),n=e.getPropertyValue("overflow"),o=e.getPropertyValue("display");i&&"static"!==i||(t.style.position="relative"),"hidden"!==n&&(t.style.overflow="hidden"),o&&"inline"!==o||(t.style.display="block"),0===t.clientHeight&&(t.style.height="100%"),t.classList.add("object-fit-polyfill")},e=function(t){var e=window.getComputedStyle(t,null),i={"max-width":"none","max-height":"none","min-width":"0px","min-height":"0px"};for(var n in i){var o=e.getPropertyValue(n);o!==i[n]&&(t.style[n]=i[n])}},i=function(i){var n=i.parentNode;t(n),e(i),i.style.position="absolute",i.style.height="100%",i.style.width="auto",i.clientWidth>n.clientWidth?(i.style.top="0",i.style.marginTop="0",i.style.left="50%",i.style.marginLeft=i.clientWidth/-2+"px"):(i.style.width="100%",i.style.height="auto",i.style.left="0",i.style.marginLeft="0",i.style.top="50%",i.style.marginTop=i.clientHeight/-2+"px")},n=function(){for(var t=document.querySelectorAll("[data-object-fit]"),e=0;e<t.length;e++){var n=t[e].nodeName.toLowerCase();"img"===n?t[e].complete?i(t[e]):t[e].addEventListener("load",function(){i(this)}):"video"===n&&(t[e].readyState>0?i(t[e]):t[e].addEventListener("loadedmetadata",function(){i(this)}))}return!0};document.addEventListener("DOMContentLoaded",function(){n()}),window.addEventListener("resize",function(){n()}),window.objectFitPolyfill=n}();


// Ensure NOFO and NOFO.Utils exist
var NOFO = NOFO || {};
NOFO.Utils = NOFO.Utils || {};
NOFO.Components = NOFO.Components || {};
NOFO.Constants = NOFO.Constants || {};

//****************************
// Off canvas side menu - show/hode sub menu items
//****************************
$(document).ready(function () {
    $(".nofo-menu-mobile-has-sub > a").click(function () {
        $(this).siblings(".nofo-sub-menu").toggleClass('open');
        $(this).parent().siblings(".nofo-menu-mobile-has-sub").children('.nofo-sub-menu').removeClass('open');
    });
});
//****************************
// Isotope - filter items on button click
//****************************
$('.nofo-isotope-filters').on('click', 'li', function () {
    var filterValue = $(this).attr('data-filter');
    $('.nofo-isotope-news').isotope({ filter: filterValue });
    $('.nofo-isotope-course').isotope({ filter: filterValue });
    $('.nofo-isotope-search').isotope({ filter: filterValue });
    $('.nofo-isotope-oppl').isotope({ filter: filterValue, });
    $('.nofo-isotope-contact').isotope({ filter: filterValue, itemSelector: '.grid-item' });
    $('.nofo-isotope-filters li').removeClass('active');
    $(this).addClass('active');
});


NOFO.Utils.Normalization = (function () {
    function init() {
        removeWhiteSpaceAroundSvg();
        repalaceHyphen()
    }

    
    function repalaceHyphen() {
        $('.nofo-hero-text').html($('.nofo-hero-text').text().replace('-', '&shy'));
    }
    
    function removeWhiteSpaceAroundSvg() {
        var $svgList = $('svg:visible'); //getBBox() only works for visible elements
        $svgList.each(function (key, svg) {
            try{
                var bbox = svg.getBBox();
                var viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
                svg.setAttribute("viewBox", viewBox);

            }catch(e){
                console.error('Firefox unable to getBBox');
            }



        });
    }

    return {
        init: init,
        removeWhiteSpaceAroundSvg: removeWhiteSpaceAroundSvg
    }

})();

$(document).ready(function () {
    if (NOFO && NOFO.Utils && NOFO.Utils.Normalization) {
        NOFO.Utils.Normalization.init();
    } else {
        console.error('NOFO.Utils.Normalization is not defined');
    }
});

//****************************
// Isotope
//****************************

var $container = [$('.nofo-isotope'), $('.nofo-isotope-course'), $('.nofo-isotope-news'), $('.nofo-isotope-search'), $('.nofo-isotope-oppl'), $('.nofo-isotope-contact')];
//Initialize isotope on each container
jQuery.each($container, function (j) {
    this.isotope({
        itemSelector: '.grid-item',
        layoutMode: 'fitRows',
        getSortData: {
            name: '.name',
            symbol: '.symbol',
            number: '.number parseInt',
            category: '[data-category]',
            weight: function (itemElem) {
                var weight = $(itemElem).find('.weight').text();
                return parseFloat(weight.replace(/[\(\)]/g, ''));
            }
        }
    });
});

NOFO.Components.Accordion = (function () {

    function init() {
        attachEventHandlers();
    };

    function attachEventHandlers() {


        $('.nofo-isotope-filters +  div .accordion-item').bind('mouseup', (function (e) {
            adjustIsoTopeContainerHeight(e, '.accordion-content')
        })); //Use mouseup instead of click to be after the accordion click.

    };

    function isLeftMouseClick(e) {
        return e.which === 1;
    }

    /**
    * Adjust the isotope container height when an accordion is inside an isotope container
    * Not a perfect solution, but works as long as the accordion is not abused by clicking multiple times
    * @param e jquery mouseup event.
    * @param A jquery selector. The element is added/removed. Must exist inside the accordion-item,
    * @param forceAdd Use this if the height is going to be added.
    */
    function adjustIsoTopeContainerHeight(e, contentSelector, forceAdd) {
       
        if (!isLeftMouseClick(e)) {
            return; //We ignore right and middle clicks.
        }

        var $accordionMenuItem = $(e.target).closest('.accordion-item');

        var $closestArticle = $accordionMenuItem.closest('.nofo-article-content');

        var newHeight = $closestArticle.height();
        var addedHeight = $accordionMenuItem.find(contentSelector).outerHeight() + 2;
        setTimeout(function () {
  
         
            if ($accordionMenuItem.find('.accordion-title').attr('aria-expanded') === 'true' || forceAdd) {
                addedHeight = $accordionMenuItem.find(contentSelector).outerHeight() + 2;
                newHeight += addedHeight;
            } else {
                newHeight -= addedHeight;
            }
            $closestArticle.css({ height: newHeight });
        }, 0); //Timing hack to be executed after the accordion event

    }

    return {
        init: init,
        adjustIsoTopeContainerHeight: adjustIsoTopeContainerHeight
    };

})();

$(document).ready(function () { //Do not use document.ready! See: http://isotope.metafizzy.co/v1/docs/help.html#imagesloaded_plugin
    NOFO.Components.Accordion.init();
});



// Start course page to sort on calendar
$container[1].isotope({
    filter: '.nofo-calendar'
});
// Start contact page to sort on office
$container[5].isotope({
    filter: '.nofo-office'
});





//****************************
// Accordion - expand/collapse all
//****************************
$(document).ready(function () {
    $("#nofo-accordion-toggle").click(function () {
        $(this).toggleClass('nofo-accordion-toggle-active');
        var btnclass = $(this).hasClass('nofo-accordion-toggle-active');
        $(".accordion-item").each(function () {
            if (!$(this).hasClass('is-active') && btnclass) {
                $(this).children('.accordion-title').trigger('click');
            } else if ($(this).hasClass('is-active') && !btnclass) {
                $(this).children('.accordion-title').trigger('click');
            }
        });
    });
});

// Check to see if all accordions have been opened manually
$(".accordion-title").click(function () {
    if ($(".accordion-item.is-active").length == $(".accordion-item").length) {
        // all accordion items are open
        $('#nofo-accordion-toggle').addClass('nofo-accordion-toggle-active');
    } else {
        $('#nofo-accordion-toggle').removeClass('nofo-accordion-toggle-active');
    }
});

//****************************
// Allow map to scroll on click
//****************************
$('.nofo-iframe-gm-container').click(function () {
    $(this).find('iframe').css("pointer-events", "auto");
});

//****************************
// Set height of video to always fill the page
//****************************
$(document).ready(function () {
    var calloutHeight = 0;
    var max = 730;
    if ($(".nofo-callout-full").length > 0) {
        calloutHeight = $(".nofo-callout-full").innerHeight();
    }
    function videoHeight() {
        var videoH = $(window).innerHeight() - $('.title-bar.nofo-off-canvas').innerHeight() - $('#widemenu').innerHeight() - calloutHeight - 6 /*- $('.nofo-hero').height() + 150*/;
        if(videoH > max){
            videoH = max;
        }
        $('.nofo-hero').css('max-height', videoH);
    }
    if ($(window).width() > 500) {
        videoHeight();
        $(window).resize(function () {
            videoHeight();
        });
    }
});



//****************************
// Add extra person in signup form for course
//****************************
$('.nofo-btn-add').click(function (e) {
    $(this).parent().siblings('.nofo-signup-participant:last').clone().insertBefore($(this).parent());;
    $(this).parent().siblings('.nofo-signup-participant:last').find(':input').val('');
    //NOFO.Components.Accordion.adjustIsoTopeContainerHeight(e, '.nofo-signup-participant', true);
});




//****************************
// Scrolltop on off-canvas-wrapper-inner should minify top navbar and set sidebar on news content fixed
//****************************
NOFO.Constants.ScreenSize = {
    XLARGE : 1237,
    LARGE: 1024,
    MEDIUM: 640,
    
};


var $toolTip;

$(window).scroll(function () {

    //Remove the tooltip on scroll to avoid focus bug.
    $toolTip = $toolTip || $('.nofo-social a');
    if ($toolTip.length > 0) {
        $toolTip.foundation('hide');
    }

    //Disable scrolling
    if (window.innerWidth < NOFO.Constants.ScreenSize.LARGE) {
        return;
    }

   

    var $sideBar = $(".nofo-news-content-sidebar, .calendar-sidebar");
    if ($sideBar.length === 0) {
        return;
    }

    var startScroll = $("#hero").innerHeight() + $("#widemenu").innerHeight() + $(".title-bar.nofo-off-canvas").innerHeight();

    if (window.innerWidth > window.innerWidth < NOFO.Constants.ScreenSize.LARGE && $(this).scrollTop() > startScroll) {

        if (!$sideBar.hasClass('nofo-news-content-sidebar-fixed')) {
            if (window.innerWidth > 1237) {
                var sidebarLeft = ($(window).width() - ($sideBar.offset().left + $sideBar.outerWidth()));
                $sideBar.css("right", sidebarLeft);
                $sideBar.addClass("nofo-news-content-sidebar-fixed");

            } else {

                $sideBar.css("right", 16);
                $sideBar.addClass("nofo-news-content-sidebar-fixed");

            }
        }
    }
    else {
        $sideBar.removeClass("nofo-news-content-sidebar-fixed");
        $sideBar.css("right", 0);
    }
});




//****************************
// Scroll inside sidebar fixed
//****************************

$(document).ready(function () {
    $('.nofo-news-content-sidebar').on('mousewheel', function (event) {
        if ($(this).hasClass("nofo-news-content-sidebar-fixed")) {
            var target = $(".off-canvas-wrapper-inner");
            var targetScroll = target.scrollTop();
            var scrollFixed = event.deltaY * event.deltaFactor;
            target.prop("scrollTop", targetScroll + -scrollFixed);
        }
    });
});

//****************************
// Toggle scroll when off canvas is toggled
//****************************
$('.nofo-off-canvas .menu-icon').click(function () {
    $('.off-canvas-wrapper-inner').toggleClass('is-open-right');

    if ($('.off-canvas-wrapper-inner').hasClass('nofo-no-scroll')) {
        $('.off-canvas-content').css('margin-top', -scrolltopWrapper);
    } else {
        $('.off-canvas-content').css('margin-top', 0);
    }
});

$('.js-off-canvas-exit').click(function () {
    $('.off-canvas-wrapper-inner').removeClass('nofo-no-scroll');
    $('#nav-icon').toggleClass('open');
    $('.off-canvas-content').css('margin-top', 0);

});

$('.nofo-off-canvas-action-search a').click(function () {
    $('.nofo-off-canvas-action-searchinput').focus().toggleClass('active');
    $('.nofo-off-canvas-action-searchinput input').focus();

});


/* CODE PRETTIFY: Prettify code snippets in HTML */
$(document).ready(function () {
    $('.html-escape').each(function () {
        $(this).text($(this).html());
    });
});

/**
* NOFO - ImageGallery.
*
* Creates an image gallery with the combination of isotope (auto-packer), slick(image-slider) and foundation reveal (modal).
*
* @type {{}}
*/

NOFO.Components.ImageGallery = (function () {
    var _config = {};
    var _defaults = {
        isotope: {
            itemSelector: '.nofo-gallery-image',
            masonry: {
                columnWidth: '.grid-sizer'
            },
            percentPosition: true,
        }
    };

    function init(config) {
        config = config || {};
        _config = $.extend(_defaults, config)

        initIsotopeGallery();
        addClickEventsHandlers();
        addCloseModelEvent();
    }

    function addCloseModelEvent() {
        getModal().on('closed.zf.reveal', function () {
            getSlider().slick('unslick');
        })
    }

    function initIsotopeGallery() {
        getGallery().isotope(_config.isotope);
    }

    function getModal() {
        return $('#nofo-gallery-modal');
    }
    function getGallery() {
        return $('.nofo-gallery');
    }
    function getSlider() {
        return $('.nofo-gallery-slides');
    }

    function openModal() {
        getModal().foundation('open');
    }

    function handleImageClick(event) {
        event.preventDefault();
        var $clickedImage = $(event.target);
        openModal();
        initSlideGallery($clickedImage);

    }

    function addClickEventsHandlers() {
        var $images = $('.nofo-gallery').find('.nofo-gallery-image');
        $images.unbind('click').bind('click', handleImageClick);
    }

    function getImages($clickedImage) {
        var $container = $clickedImage.closest('.nofo-gallery');
        return $container.find('img');
    }

    function mapImagesToSlides($images, $clickedImage) {
        var slides = [];
        var startIndex = 0;
        $.each($images, function (index, $image) {
            var $imageClone = $($image).clone();
            var $slide = $('<div class="slick-slide"></div>');
            $slide.append($imageClone);
            if ($imageClone.attr('src') === $clickedImage.attr('src')) {
                startIndex = index;
            }
            slides.push($slide);
        });

        return { slides: slides, startIndex: startIndex };
    }

    function initSlideGallery($clickedImage) {

        var $orbit = getModal().find('.nofo-gallery-slides');
        var $images = getImages($clickedImage);
        var __ret = mapImagesToSlides($images, $clickedImage);
        var slides = __ret.slides;
        var startIndex = __ret.startIndex;

        for (var ii = 0; ii < slides.length; ii++) {
            $orbit.append(slides[ii]);
        }

        renderSlideGallery(startIndex);
    }

    function renderSlideGallery(startIndex) {
        getSlider().slick({
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            initialSlide: startIndex,
            focusOnSelect: true
        });
    };

    return {
        init: init
    }
})();

$(window).load(function () { //Do not use document.ready! See: http://isotope.metafizzy.co/v1/docs/help.html#imagesloaded_plugin
    NOFO.Components.ImageGallery.init();
});



//Ellipsis uses Clamp to make lines crossfunctional and not only work in Webkit browsers. See https://css-tricks.com/line-clampin/
NOFO.Components.Ellipsis = (function (clamp) {
    var _typesOfClamps = [2, 3, 4];

    function initEllipse(rows, isReset) {

        var $elements = $('.nofo-ellipsis-' + rows);
        $.each($elements, function (key, element) {
            var $element = $(element);
            if (isReset === true) {
                $element.text($element.data('original'));
            } else {
                $element.data('original', $(element).text());
            }

            clamp(element, rows)

        });
    };


    function reset() {
        for (var ii = 0; ii < _typesOfClamps.length; ii++) {
            initEllipse(_typesOfClamps[ii], true);
        }
    }
    function init() {
        for (var ii = 0; ii < _typesOfClamps.length; ii++) {
            initEllipse(_typesOfClamps[ii], false);
        }
        $(window).on('resize', function () {
            NOFO.Components.Ellipsis.reset();
        })
    }

    return {
        init: init,
        reset: reset
    }
})(window.clamp);

$(document).ready(function () {
    NOFO.Components.Ellipsis.init();
});


NOFO.Utils.Scrolling = (function () {

    //Fixes refresh when mobile scrolls 
    //See https://github.com/luster-io/prevent-overscroll
    function overscroll (el) {
        el.addEventListener('touchstart', function () {
            var top = el.scrollTop
              , totalScroll = el.scrollHeight
              , currentScroll = top + el.offsetHeight
            //If we're at the top or the bottom of the containers
            //scroll, push up or down one pixel.
            //
            //this prevents the scroll from "passing through" to
            //the body.
            if (top === 0) {
                el.scrollTop = 1
            } else if (currentScroll === totalScroll) {
                el.scrollTop = top - 1
            }
        })

        el.addEventListener('touchmove', function (evt) {
            //if the content is actually scrollable, i.e. the content is long enough
            //that scrolling can occur
            if (el.offsetHeight < el.scrollHeight)
                evt._isScroller = true
        })
    }

    function init() {
        var el = document.querySelector('.off-canvas-wrapper-inner');
        if(el){
            overscroll(el);

        }

        document.body.addEventListener('touchmove', function (evt) {
            //In this case, the default behavior is scrolling the body, which
            //would result in an overflow.  Since we don't want that, we preventDefault.
            if (!evt._isScroller) {
                evt.preventDefault()
            }
        });
    }

    return {
        init : init
    }
})();

$(document).ready(function () {
    //NOFO.Utils.Scrolling.init();
});

NOFO.Components.Submenu = (function () {
    var init = function () {

        var $submenus = $('.main-navigation .nofo-has-submenu');
        $submenus.each(function (key, menu) {

            var onLeave = function(e){
                // check and loop relatedTarget.parentNode
                // ignore event triggered mouse overing any child element or leaving itself
                var obj=e.relatedTarget;
                while(obj!=null){
                    if(obj==this){
                        return;
                    }
                    obj=obj.parentNode;
                }
                hideSubMenu(menu);
            };

            menu.onmouseout= onLeave;
            menu.onfocusout= onLeave;
        });


        $('.nofo-has-submenu a').hover(showSubMenu).focus(showSubMenu);

    };

    var getSubmenu = function ($target) {
        return $target.next('.nofo-sub-menu');
    };

    var showSubMenu = function (e) {
        var $target = $(e.target);
        var $subMenu = getSubmenu($target);

        $target.addClass('visible');
        $subMenu.addClass('visible');
    };

    var hideSubMenu = function (menu) {
        var $menu = $(menu);
        var $subMenu = $menu.find('.nofo-sub-menu');
        var $menuItem = $menu.find(' > a.visible');

        $menuItem.removeClass('visible');
        $subMenu.removeClass('visible');
    };




    return {
        init : init
   }
})();

$(document).ready(function () {
    NOFO.Components.Submenu.init();
});


NOFO.Components.NewsArchive = (function () {

    var _pageId;
    var _language = {
        readMore : 'Les mer', //Fallback
    };

    var lang = "no";

    var _state = {}; //Controls the tabs, who many is skipped etc.

    var getLanguage = function () {
        return lang;
    };

    var createQuery = function (skip, take, category,pageId) {
      var language = getLanguage();
      var url = '/'+language+'/NewsArchive?skip=' + skip + '&take=' + take + '&lang=' + lang;

      if(category){
          category = category.replace('.nofocat-', '').toLowerCase();
          url = url + '&category='+ category;
      }
      if(pageId){
          url = url + '&pageId='+ pageId;
      }
      return url;
    };

    var setState = function ($filters) {

        $.each($filters, function (key, filter) {
            var $filter = $(filter);

          var dataFilter = $filter.attr('data-filter');
          var $elements = $(dataFilter);

          _state[dataFilter] = {
              skip : 5,
              take : 6,
              isLoaded : $elements.length < 5
          }
        })
    };

    var getState = function ($currentTab) {
        var $currentTab = $currentTab || $('.nofo-isotope-filters li.active');
        var filterValue = $currentTab.attr('data-filter');
        return _state[filterValue];
    };

    var onLoadNews = function (skip, take, category, pageId) {

        var url = createQuery(skip, take, category, pageId);
        var state =  getState();
        var length = 0;


        $.getJSON(url,
            function (data) {
                $.each(data,
                    function (key, news) {
                        $.each(news,
                            function (index, article) {
                                var filterClass = category || ".nofocat-alle";
                                filterClass = filterClass.replace('.', '');

                                var html = $('<div class="' + filterClass + ' grid-item medium-6 large-4 columns mb-2"><a href="' + article.ArticleUrl + '" class="article-box article-box--isotope group"> ' +
                                             '<div class="article-box__img"><img src="' + article.ImageUrl + '" alt=""></div>' +
                                             '<div class="article-box__content"><span class="article-box__content__title mb-2 ellipsis-2">' + article.MainIntro + '</span>' +
                                             '<button class="nofo-readmore nofo-readmore--uppercase">'+_language.readMore+'<span class="nofo-readmore-arrow"></span></button>' +
                                             '</div></a></div>');

                                $("#archive").isotope('insert', html);

                                state.skip++;
                                length++;
                            });
                    });

                    if($.isEmptyObject(data) || length % state.take !== 0){
                        state.isLoaded = true;
                        $('#nofo-isotope-load-more').hide();
                    }
            });
    };

    var getActiveFilterValue = function () {
      var $activeFilter= $('.nofo-isotope-filters li.active');
      var $firstFilter = $('.nofo-isotope-filters li:first');

      var activeFilter = $activeFilter.attr('data-filter');
      var firstFilter = $firstFilter.attr('data-filter');

      var filter;
      if(firstFilter !== activeFilter){
          filter = activeFilter;
      }

      return filter;
    };

    var initFilters = function () {
        var $isoTope = $('.nofo-isotope-news, .nofo-isotope-course');
        var $filters = $('.nofo-isotope-filters');

        if ($filters.length > 0) {
            var $filterTabs = $filters.find('li');
            var $initFilter = $filterTabs.first();
            $initFilter.addClass('active');
            var filterValue = $initFilter.attr('data-filter');
            $isoTope.isotope({ filter: filterValue });

            setState($filterTabs);

            $filterTabs.click(function () {
                var $currentTab = $(this);
                var state = getState($currentTab);
                if(state.isLoaded){
                    $('#nofo-isotope-load-more').hide();
                }else{
                    $('#nofo-isotope-load-more').show();
                }
            });
        }
    };

    var initMoreBtn = function () {
        $("#moreButton").click(function (e) {
            e.preventDefault();

            var filter = getActiveFilterValue();
            // $("#nofo-isotope-load-more").hide();
            var state = getState();
            onLoadNews(state.skip, state.take, filter, _pageId);


        });
    };

    var init = function (options) {
        var options = options || {}
        _pageId = options.pageId;
        _language = options.language || _language;
        lang = options.language.branch || lang;
        
        initFilters();
        initMoreBtn();


    };

    return {
        init: init,
        onLoadNews : onLoadNews,
        getActiveFilterValue : getActiveFilterValue
    }

})();


